html, body {
    margin: 0;
  }
  
  body {
    /* IE10+ */
    background-image: -ms-linear-gradient(top, #EBEBEB 0%, #999999 100%);
  
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #EBEBEB 0%, #999999 100%);
  
    /* Opera */
    background-image: -o-linear-gradient(top, #EBEBEB 0%, #999999 100%);
  
    /* Webkit (Safari/Chrome 10) */
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #EBEBEB), color-stop(100, #999999));
  
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #EBEBEB 0%, #999999 100%);
  
    /* W3C Markup */
    background-image: linear-gradient(to bottom, #EBEBEB 0%, #999999 100%);
  
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
  }
  
  .navbar {
    /* IE10+ */
    background-image: -ms-linear-gradient(top, #227EA8 0%, #155080 100%);
  
    /* Mozilla Firefox */
    background-image: -moz-linear-gradient(top, #227EA8 0%, #155080 100%);
  
    /* Opera */
    background-image: -o-linear-gradient(top, #227EA8 0%, #155080 100%);
  
    /* Webkit (Safari/Chrome 10) */
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #227EA8), color-stop(100, #155080));
  
    /* Webkit (Chrome 11+) */
    background-image: -webkit-linear-gradient(top, #227EA8 0%, #155080 100%);
  
    /* W3C Markup */
    background-image: linear-gradient(to bottom, #227EA8 0%, #155080 100%);

    margin-bottom: 0px;
    padding-top: 0;
    padding-bottom: 0;
  
    & > .container {
      width: auto;
    }

    & .dropdown-menu {
      font-size: 14px;
    }

    & .dropdown-item {
      padding: 3px 20px;
    }
  
    & .navbar-nav > li {
      & > a:hover,
      & > a:focus,
      &.active > a,
      &.active > a:hover,
      &.active > a:focus,
      &.open > a,
      &.open > a:hover,
      &.open > a:focus {
        background-color: #007bad;
        color: white;
      }
  
      & > a {
        color: white;
      }
    }
  }
  
  .navbar-light .navbar-nav .nav-link {
      color: white;
      padding : 15px;

      &.active {
        color: white;
      }


      &:hover,
      &:focus {
        background-color: #007bad;
        color: white;
      }
  }

  .content-container {
    background-color: white;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
  }

  .fancy-grid-column {
    display: table-row;
  }

  .fancy-grid-body {
    display: table-row-group;
  }

  .fancy-grid-body-row {
    display: table-row;
  }

  .fancy-grid-footer-row {
    display: table-row;
    & td {
      display: table-cell;
      flex: unset;
    }
  }

  .disabled {
    color: #999;
  }